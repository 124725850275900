export default [
  'CANDIDATES',
  'OPENINGS',
  'REVIEWS',
  'USERS',
  'POSITIONS',
  'RESPONSIBILITIES',
  'RESPONSIBILITY_ARCHIVE',
  'CERTIFICATION',
  'CERTIFICATION_ARCHIVE',
  'EDUCATION',
  'EDUCATION_ARCHIVE',
  'SKILLS',
  'SKILLS_ARCHIVE',
  'LANGUAGES',
  'LANGUAGES_ARCHIVE',
  'EXPERIENCES_ARCHIVE',
  'EXPERIENCES',
  'WORKING_CONDITIONS_ARCHIVE',
  'WORKING_CONDITIONS',
  'ADDITIONAL_INFORMATION_ARCHIVE',
  'ADDITIONAL_INFORMATION',
  'AUDIT_LOGS_GENERAL',
  'AUDIT_LOGS_INTERVIEWS',
  'AUDIT_LOGS_JOBS',
  'LEVEL_ANALYSIS_COMPETENCIES',
  'LEVEL_ANALYSIS_SKILLS',
  'AUDIT_LOGS_SIGNOFFS',
  'COMPETENCIES',
  'COMPETENCY_ARCHIVE',
  'JOBS',
  'JOBS_ARCHIVE',
  'WAREHOUSE_COMPETENCIES',
  'WAREHOUSE_RESPONSIBILITIES',
  'WAREHOUSE_SKILLS',
  'WAREHOUSE_CERTIFICATIONS',
  'WAREHOUSE_EDUCATION',
  'WAREHOUSE_JOBS',
  'GUIDES',
  'JOB_SIGNOFFS',
  'COMPETENCY_QUESTIONS',
  'SURVEYS',
  'TOPIC_QUESTIONS',
  'WAREHOUSE_INTERVIEW_QUESTIONS',
  'API_KEYS',
  'TASKS'
].reduce((acc, cv) => ({ ...acc, [cv]: cv }), {});
