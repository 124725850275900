<template>
  <div
    class="text-sm flex items-center gap-0.75 rounded-full pt-px pb-0.5 pr-1.5"
    :class="[
      count > 0 ? 'bg-light-blue-1 !text-blue-1 font-medium gap-0.5 pl-2' : 'font-normal',
      disabled ? 'text-grey-5' : 'text-link'
    ]"
  >
    <slot name="label">
      {{ label }}
    </slot>

    <div v-if="count > 0 && multiple">
      ({{ count }})
    </div>

    <div
      v-if="count > 0"
      class="flex items-center"
    >
      <XIcon
        class="cursor-pointer pt-px h-3.25 w-3.25 text-blue-2"
        @click.stop="$emit('clear')"
      />
    </div>

    <ChevronDownIcon
      v-else
      class="chev-small inline-block"
      :class="[
        chevronClass,
        { '!cursor-default': disabled }
      ]"
    />
  </div>
</template>

<script>
import { XIcon, ChevronDownIcon } from '@heroicons/vue/solid';

export default {
  components: {
    XIcon,
    ChevronDownIcon
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chevronClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['clear']
};
</script>
